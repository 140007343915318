<template>
    <div class="contract-create">
        <pay-box title="新增模板合同">
            <div class="form-box">
                <div class="row">
                    <div class="label">公司身份</div>
                    <div class="content">
                        <div class="input-container">
                            <a-radio-group name="radioGroup" :default-value="radio" @change="radioChange" size="large">
                                <a-radio :value="1">甲方</a-radio>
                                <a-radio :value="2">乙方</a-radio>
                            </a-radio-group>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="label label-required">协议标题</div>
                    <div class="content">
                        <div class="input-container">
                            <a-input v-model="title" placeholder="请输入不超过50字的协议标题" size="large" style="border-radius: 0;" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="label">协议示范</div>
                    <div class="content">
                        <div class="demo-container">
                            <div class="text">
                                <div>甲方：嘉泽形象美学（示例）</div>
                                <div>身份证号：123456789098165432（示例）</div>
                                <div>联系方式：12345678909（示例）</div>
                                <div>乙方：李沧区嘉泽女子教育咨询中心</div>
                                <div>注册地址：/</div>
                                <div>法定代表人：/</div>
                                <div style="margin: 0;">联系方式：/</div>
                            </div>
                            <div class="tips">
                                <img src="/assets/images/contract-icon-tips.png">
                                <span>抬头由系统自动生成</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="label">协议内容</div>
                    <div class="content">
                        <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" class="editor"></quill-editor>
                    </div>
                </div>
                <div class="row">
                    <div class="label">内容示范</div>
                    <div class="content">
                        <div class="demo-container">
                            <div class="text-row">
                                <div class="left">
                                    <div>甲方：嘉泽形象美学（示例）</div>
                                    <div>签名：</div>
                                </div>
                                <div class="right">
                                    <div>乙方：李沧区嘉泽女子教育咨询中心</div>
                                    <div>盖章：</div>
                                </div>
                            </div>
                            <div class="signed"></div>
                            <div class="tips">
                                <img src="../../../../../public/assets/images/contract-icon-tips.png">
                                <span>抬头由系统自动生成</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </pay-box>

        <div class="btn-box">
            <button class="btn" @click="save">保存</button>
            <button class="btn cancel" @click="cancel">取消</button>
        </div>
    </div>
</template>

<script>
import PayBox from "@/views/AST/Pay/Step/PayBox";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
export default {
    name: "ContractCreate",
    components: {
        PayBox,quillEditor
    },
    data() {
        return {
            content: "",
			editorOption: {
				theme: "snow",
                placeholder: "请输入协议内容",
				modules: {
					toolbar: [
						[
                            { header: [1, 2, 3, 4, 5, 6, false] },
                            "bold", "italic", "underline", "strike",
                            { color: [] }, { background: [] },
                            "blockquote",{ list: "ordered" }, { list: "bullet" },{ align: [] }
                            ,"image", "link","font"
                        ],
					],
				},
			},
            title: "",
            radio: 2,
        }
    },
    watch: {
        
    },
    mounted() {
        
    },
    methods: {
        cancel(){
            this.$emit('cancel');
        },
        save(){
            console.log(333,this.content);
            this.$emit('save');
        },
        radioChange(e){
            console.log(e.target.value); 
        }
    }
}
</script>

<style lang="less" scoped>
    .cancel{
        width: 80px;
        height: 32px;
        border: 1px solid #333333;
        background: #fff;
        color: #333333;
        margin-left: 20px;
    }
    .form-box{
        width: 100%;
        .row{
           display: flex;
           margin-bottom: 24px;
           .label{
              color: #333333;
              font-size: 14px;
              line-height: 40px;
           }
           .label-required{
                position: relative;
                &::after{
                    content: '*';
                    position: absolute;
                    top: 0;
                    left: -8px;
                    color: red;
                    display: block;
                }
           }
           .content{
                flex: 1;
                margin-left: 16px;
                .input-container{
                    width: 100%;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                }
                .demo-container{
                    width: 100%;
                    background: #FAFAFA;
                    padding: 16px;
                    box-sizing: border-box;
                    position: relative;
                    .tips{
                        height: 32px;
                        background: #FEFBE6;
                        border-radius: 2px;
                        border: 1px solid #F2E3B1;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 8px;
                        position: absolute;
                        right: 16px;
                        bottom: 16px;
                        img{
                            width: 16px;
                            height: 16px;
                            margin-right: 8px;
                        }
                        span{
                            color: #606266;
                            font-size: 14px;
                            line-height: 32px;
                        }
                    }
                    .text{
                        color: #999999;
                        font-size: 14px;
                        line-height: 20px;
                        div{
                            margin-bottom: 12px;
                        }
                    }
                    .text-row{
                        width: 100%;
                        padding-right: 140px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .left,.right{
                            height: 52px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            color: #999999;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                    .signed{
                        width: 100%;
                        height: 88px;
                    }
                }
                .editor{
                    width: 100%;
                    height: 300px;
                    margin-bottom: 40px;
               }
           }
        }
    }
</style>