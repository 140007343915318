<template>
    <div class="already-set">
        <a-table class="stock-table" :columns="table.columns" :data-source="table.data" :loading="table.loading"
            :pagination="table.pagination" rowKey="id" @change="tableChanged" bordered>

            <div slot="op" class="btn-groups">
                <img src="/assets/images/contract-icon-edit.png">
                <img src="/assets/images/contract-icon-del.png">
            </div>

        </a-table>

        <div class="btn-box">
            <button class="btn" @click="create">新增模板合同</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "alreadySet",
    data() {
        return {
            table: {
                columns: [
                    {
                        width: 100,
                        title: '序号',
                        dataIndex: 'num',
                        align: 'center',
                    },
                    {
                        width: 200,
                        title: '合同设定时间',
                        dataIndex: 'created_at',
                        align: 'center',
                    },
                    {
                        width: 540,
                        title: '模板合同名称',
                        dataIndex: 'name',
                        align: 'center',
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        align: 'center',
                        scopedSlots: { customRender: 'op' },
                    }
                ],
                loading: true,
                data: [{
                    id: 1,
                    num: 1,
                    created_at: '2025-03-26 11:30',
                    name: '这是设置的合同的名称这是设置的合同的名称这是设置的合同的名称',
                    op: '操作'
                }],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        tableChanged(pagination) {
            this.table.loading = true;
            this.query.page = pagination.current;
            this.getList();
        },
        getList() {
            setTimeout(() => {
                this.table.loading = false;
            }, 1000);
        },
        create() {
            this.$emit('create');
        }
    }
}
</script>

<style lang="less" scoped>
    .btn-groups{
        width: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        img{
            width: 24px;
            height: 24px;
        }
    }
</style>