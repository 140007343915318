<template>
    <div class="signed">
        <a-table class="stock-table" :columns="table.columns" :data-source="table.data" :loading="table.loading"
            :pagination="table.pagination" rowKey="id" @change="tableChanged" bordered>

            <div slot="op" class="btn-groups">
                <img src="/assets/images/contract-icon-look.png">
                <img src="/assets/images/contract-icon-down.png">
            </div>

        </a-table>
    </div>
</template>

<script>
export default {
    name: "signed",
    data() {
        return {
            table: {
                columns: [
                    {
                        width: 70,
                        title: '序号',
                        dataIndex: 'num',
                        align: 'center',
                    },
                    {
                        width: 140,
                        title: '合同设定时间',
                        dataIndex: 'created_at',
                        align: 'center',
                    },
                    {
                        width: 200,
                        title: '模板合同名称',
                        dataIndex: 'contract_name',
                        align: 'center',
                        ellipsis:true,
                    },
                    {
                        width: 110,
                        title: '签署用户',
                        dataIndex: 'user_name',
                        align: 'center',
                        ellipsis:true,
                    },
                    {
                        width: 110,
                        title: '签署人姓名',
                        dataIndex: 'name',
                        align: 'center',
                        ellipsis:true,
                    },
                    {
                        width: 110,
                        title: '合同编号',
                        dataIndex: 'contract_num',
                        align: 'center',
                        ellipsis:true,
                    },
                    {
                        width: 140,
                        title: '合同设定时间',
                        dataIndex: 'signed_at',
                        align: 'center',
                        ellipsis:true,
                    },
                    {
                        width: 100,
                        title: '操作',
                        dataIndex: 'op',
                        align: 'center',
                        scopedSlots: { customRender: 'op' },
                    }
                ],
                loading: true,
                data: [{
                    id: 1,
                    num: 1,
                    created_at: '2025-03-26 11:30',
                    contract_name: '这是设置的合同的名称这是设置的合同的名称这是设置的合同的名称',
                    user_name: '颜创时代',
                    name: '王勇',
                    contract_num: '202532',
                    signed_at: '2025-03-26 12:48',
                    op: '操作'
                }],
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 20
                },
            },
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        tableChanged(pagination) {
            this.table.loading = true;
            this.query.page = pagination.current;
            this.getList();
        },
        getList() {
            setTimeout(() => {
                this.table.loading = false;
            }, 1000);
        },
    }
}
</script>

<style lang="less" scoped>
    .btn-groups{
        width: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        img{
            width: 24px;
            height: 24px;
        }
    }
</style>