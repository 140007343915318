<template>
    <div class="contract-page">
        <div class="page-title">签署合同设置</div>
        <div class="tabs">
            <div class="tab" :class="{active:current == 0}" @click="tabClicked(0)">
                <div class="name">签章设置</div>
                <div class="line" v-if="current == 0"></div>
            </div>
            <div class="tab" :class="{active:current == 1}" @click="tabClicked(1)">
                <div class="name">已设合同</div>
                <div class="line" v-if="current == 1"></div>
            </div>
            <div class="tab" :class="{active:current == 2}" @click="tabClicked(2)">
                <div class="name">已签合同</div>
                <div class="line" v-if="current == 2"></div>
            </div>
        </div>

        <set-signature v-show="current == 0"></set-signature>

        <div v-show="current == 1">
            <contract-create v-if="isCreate" @cancel="cancelClicked"></contract-create>
            <already-set v-else @create="createClicked"></already-set>
        </div>

        <signed v-show="current == 2"></signed>
    </div>
</template>

<script>
import setSignature from './contract/setSignature';
import alreadySet from './contract/alreadySet';
import signed from './contract/signed';
import ContractCreate from './contract/ContractCreate';

export default {
    components: {
        setSignature,alreadySet,signed,ContractCreate
    },
    data() {
        return {
            current:0,
            isCreate: false,
        }
    },
    watch: {
        
    },
    mounted() {
        
    },
    methods: {
        tabClicked(index) {
            this.current = index;
            this.isCreate = false; 
        },
        createClicked(){
            this.isCreate = true;
        },
        cancelClicked(){
            this.isCreate = false; 
        }
    }
}
</script>

<style lang="less" scoped>
    .contract-page{
        cursor: default;
    }
    .page-title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-bottom: 40px;
    }
    .tabs {
        display: flex;
        align-items: center;
        .tab{
            height: 34px;
            margin-right: 64px;
            position: relative;
            .name{
                color: #999999;
                font-size: 16px;
                line-height: 22px;
            }
           .line{
                width: 100%;
                height: 2px;
                background: #333;
                position: absolute;
                bottom: 0;
                left: 0;
           }
        }
        .active{
            .name{
                color: #333;
            }
        }
    }
</style>