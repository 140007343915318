<template>
    <div class="set-signature">
        <pay-box title="签章设置">
            <div class="content">
                <div class="label">企业印章图片</div>
                <div class="img">

                </div>
            </div>
        </pay-box>

        <div class="btn-box">
            <button class="btn">保存</button>
        </div>
    </div>
</template>

<script>
import PayBox from "@/views/AST/Pay/Step/PayBox";
export default {
    name: "setSignature",
    components: {
        PayBox,
    },
    data() {
        return {
        }
    },
    mounted() {
        
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.content{
    display: flex;
    .label{
        color: #333333;
        font-size: 16px;
        margin-right: 16px;
    }
    .img{
        width: 164px;
        height: 164px;
        background: #999;
    }
}
.btn-box {
    margin-top: 24px;
}
</style>